.app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: Rubik, sans-serif;
}

.fill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.session-button {
  padding: 8px 13px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

.logo-image {
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
  border: 2px solid #ccc;
  border-radius: 10px;
}
